@import '../../styles/base/variables';

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  background-color: $gb_grey_055;

  p {
    flex: 1 1 0%;
    text-align: left;
    margin: 0;
    padding-inline: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  button {
    width: unset;
    margin-left: auto;

    &.success {
      background-color: $gb_positive_green;
      border-color: $gb_positive_green;

      &.nord {
        background-color: $gb_gdds_success;
        border-color: $gb_gdds_success;
      }
    }
  }
}
